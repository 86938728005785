import { memo } from 'react'
import PropTypes from 'prop-types'
import Flex from 'shared/Flex'
/**
 * Name: ProductCardFooter
 * Desc: render bottom section of product card , here we show the product price
 * @param {object} children
 */

const ProductCardFooter = ({ children, ...rest }) => {
  return (
    <Flex h="100%" w="100%" {...rest}>
      {children}
    </Flex>
  )
}

// Props Validation
ProductCardFooter.propTypes = {
  children: PropTypes.object,
}

export default memo(ProductCardFooter)
