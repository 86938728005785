import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import Icon from 'shared/Icon'
import { InputGroup } from 'shared/InputGroup'
import { ENTER_KEY_CODE } from '~/services/utils/keyboardUtils/keyboardCodeConstants'
import { LINE_NOTE_MAX_LENGTH } from 'shared/ProductCard/ProductCardConstants'

import styles from './ProductContentLineNote.module.scss'
import { checkMaxDisplayLength } from './ProductContentLineNoteUtils'

const ProductContentLineNote = ({
  productCode,
  lineNote,
  orderProductId,
  onSaveNote,
  isNoteDisable,
  lineNotePlaceholder,
}) => {
  const [productLineNote, setProductLineNote] = useState(
    checkMaxDisplayLength(lineNote)
  )
  useEffect(() => {
    setProductLineNote(checkMaxDisplayLength(lineNote))
  }, [lineNote])
  const handleLineNoteChange = (e) => {
    const note = e.target.value.replace(/\r?\n/gi, '')
    if (
      note.length <= LINE_NOTE_MAX_LENGTH ||
      productLineNote.length > note.length
    ) {
      setProductLineNote(note)
    }
  }
  const handleCallToSaveLineNote = (event) => {
    event.preventDefault()
    onSaveNote?.(orderProductId, productLineNote)
  }
  const onKeyUp = (event) => {
    if (event.which === ENTER_KEY_CODE) {
      onSaveNote(orderProductId, productLineNote)
      event.preventDefault()
      return false
    }
  }

  return (
    <div className={styles.noteWrapper}>
      <div className={styles.notePrepend}>
        <Icon variant="lineNote" />
      </div>
      <InputGroup
        className={styles.noBoxShadow}
        type="textarea"
        name="note"
        data-test="product-line-note"
        id={`note-${productCode}-${orderProductId}`}
        value={productLineNote}
        onChange={handleLineNoteChange}
        onBlur={handleCallToSaveLineNote}
        placeholder={lineNotePlaceholder}
        onKeyUp={onKeyUp}
        disabled={!isNoteDisable}
        inputAriaLabel={lineNotePlaceholder}
      />
    </div>
  )
}
ProductContentLineNote.defaultProps = {
  isNoteDisable: true,
}

ProductContentLineNote.propTypes = {
  productCode: PropTypes.string,
  lineNote: PropTypes.string,
  orderProductId: PropTypes.string,
  onSaveNote: PropTypes.func,
  isNoteDisable: PropTypes.bool,
  lineNotePlaceholder: PropTypes.string,
}

export default ProductContentLineNote
