export default {
  en: {
    offLabel: 'off',
    quantity: 'Quantity',
  },
  fr: {
    offLabel: 'de rabais',
    quantity: 'Quantité',
  },
}
