import PropTypes from 'prop-types'
import Price from '~/components/shared/Price'
import Text from '~/components/shared/Text'
import styles from './ProductDiscountWithLineThrough.module.scss'

function ProductDiscountWithLineThrough({
  regularPriceText,
  language,
  regularPrice,
}) {
  return (
    <div
      className={styles.registerPrice}
      data-product-footer="hidden"
      data-test="product-with-line-through"
    >
      <Text text={regularPriceText} />
      <Text
        text={<Price language={language}>{regularPrice}</Price>}
        lineThrough
      />
    </div>
  )
}

ProductDiscountWithLineThrough.propTypes = {
  language: PropTypes.string,
  regularPrice: PropTypes.any,
  regularPriceText: PropTypes.string,
}

export default ProductDiscountWithLineThrough
