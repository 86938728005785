import classNames from 'classnames'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { GRID_VIEW } from 'shared/ProductCard/ProductCardConstants'
import styles from './ProductCardBody.module.scss'
import Box from 'shared/Box'
/**
 * Name: ProductCardBody,
 * Desc: render product body contents
 * @param {element} childrens
 * @param {string} view
 */
const ProductCardBody = ({ children, view }) => {
  const isNotGridView = view !== GRID_VIEW
  const productDetail = classNames(styles.productDetail, {
    [styles[view]]: isNotGridView,
  })

  return <Box className={productDetail}>{children}</Box>
}

// Props Validation
ProductCardBody.propTypes = {
  view: PropTypes.string,
  children: PropTypes.node,
}

export default memo(ProductCardBody)
