import PropTypes from 'prop-types'
import Text from 'shared/Text'
import Price from 'shared/Price/Price'
import styles from './ProductSecondaryPrice.module.scss'

/**
 * Name: ProductSecondaryPrice
 * Desc: Render product secondary price
 * @param {string} language
 * @param {string} secondaryPriceText
 * @param {number} secondaryPrice
 */
const ProductSecondaryPrice = ({
  language,
  secondaryPriceText,
  secondaryPrice,
}) => {
  return (
    <div className={styles.productContentCenter}>
      <Text
        text={<Price language={language}>{secondaryPrice}</Price>}
        variant="xxlgText"
        as="div"
        className={styles.originalPrice}
      />
      <Text text={secondaryPriceText} variant="smText" />
    </div>
  )
}

// Props Validation
ProductSecondaryPrice.propTypes = {
  language: PropTypes.string.isRequired,
  secondaryPriceText: PropTypes.string,
  secondaryPrice: PropTypes.number,
}

export default ProductSecondaryPrice
