import classNames from 'classnames'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import {
  GRID_VIEW,
  GRID_VIEW_SM,
  FREE_GIVE_AWAY,
  LIST_VIEW_EXTRA_LARGE,
  LIST_VIEW_LARGE,
  NO_BACK_ORDERED,
  ZERO,
  RATING,
  IS_RATING_EDIT,
  LIST_VIEW,
} from 'shared/ProductCard/ProductCardConstants'
import {
  PRODUCT_MINIMUM_QUANTITY,
  DEFAULT_ADD_TO_CART_QUANTITY,
} from '~/components/shared/AddToCartButton/AddToCartButtonConstants'
import AddToCartButton from 'shared/AddToCartButton'
import ProductUtilityIcons from 'shared/ProductCard/ProductUtilityIcons'
import { formatProductProps } from 'shared/ProductCard/ProductCardUtils/ProductCardProps'
import { productCardPropTypes } from 'shared/ProductCard/ProductCardUtils/ProductCardPropTypes'
import { ENGLISH } from '~/redux/constants'
import Text from 'shared/Text'
import Box from 'shared/Box'
import { splitBoxProps } from '../Box/BoxUtils'
import Card, { splitCardProps } from '../Card/Card'
import Rating from 'shared/Rating'
import { noop } from '../Utils/userUtils'
import ProductAvailabilityTooltip from './ProductAvailabilityTooltip'
import styles from './ProductCard.module.scss'
import BulkBuyButton from 'shared/BulkBuyButton'
import ProductCardBody from './ProductCardBody'
import ProductCardFooter from './ProductCardFooter'
import ProductCardHeader from './ProductCardHeader'
import { getBulkBuyShow } from './ProductCardUtils'
import ProductSecondaryPrice from './ProductCardFooter/ProductSecondaryPrice'
import ProductQuantityDetail from './ProductCardFooter/ProductQuantityDetail'
import { getPriceForFooter } from './ProductCardFooter/ProductCardFooterUtils'
import ProductListViewPrice from './ProductCardFooter/ProductListViewPrice'
import ProductTotalPrice from './ProductCardFooter/ProductTotalPrice'
import ProductAvailability from './ProductCardSharedComponents/ProductAvailability'
import ProductContent from './ProductCardBody/ProductContent'
import ProductHeading from './ProductCardBody/ProductHeading'
import Checkbox from 'shared/FormControl/Checkbox'
import ProductQuickViewLoader from 'shared/ProductCard/ProductQuickView/ProductQuickViewLoader'
import {
  redirectToProductLink,
  getCheckedValue,
} from 'shared/ProductCard/ProductCardUtils/Utils'
import Button from 'shared/Button'
import ProductCardLink from '~/components/shared/ProductCard/ProductCardHeader/ProductCardLink'
import Image from '~/components/shared/Image'
import InfoLabel from '~/components/shared/InfoLabel'
import messages from './messages'
/**
 * Name: ProductCard,
 * Desc: render product card
 * @param {string} language
 * @param {bool} showYourPriceFlag
 * @param {bool} showContractCareOf
 * @param {bool} checkboxChangeHandler
 * @param {bool} showCheckbox
 * @param {object} product
 * @param {bool} addToCartEnable
 * @param {bool} isDelete
 * @param {bool} isNote
 * @param {string} view
 * @param {func} addToCartHandler
 * @param {bool} showContractIcon
 * @param {string} highlightColor
 * @param {string} showHighlight
 * @param {bool} customUnitOfMeasures
 * @param {string} variantText
 * @param {string} fontWeight
 * @param {array} selectedProducts
 * @param {number} showSecondaryPrice
 * @param {string} buyerId
 * @param {object} callingLocation
 * @param {string} pageName
 * @param {string} className
 * @param {func} onDeleteProduct
 * @param {func} updateProductQuantity
 * @param {func} onSaveNote
 * @param {bool} isSwitchToEchoAlternative
 * @param {bool} isLoggedIn
 * @param {bool} showProductStatus
 * @param {element} switchProductAmountInfo
 * @param {bool} zoomIcon
 * @param {element} customPromptElement
 * @param {number} productCardIndex
 * @param {bool} enableQuantityUpdate
 * @param {bool} showProductReviews
 * @param {bool} showQuantityDetail
 * @param {bool} showTotalPriceInline
 * @param {bool} readOnly
 * @param {object} customTopRightHeaderComponent
 * @param {bool} isDisableFooterHoverEffect
 * @param {bool} isDisableBrandName
 * @param {bool} isNoteDisable
 * @param {element} callToActionButton
 */

const ProductCard = ({
  language = ENGLISH,
  product = {},
  showQuickViewAddToCart = true,
  showYourPriceFlag = true,
  showContractCareOf,
  checkboxChangeHandler,
  showCheckbox = false,
  addToCartEnable,
  isLoggedIn,
  view = '',
  addToCartHandler,
  showContractIcon,
  showProductStatus,
  variantText,
  fontWeight,
  switchProductAmountInfo = null,
  highlightColor,
  showHighlight,
  isDelete,
  isNote,
  selectedProducts,
  className,
  showSecondaryPrice,
  callingLocation,
  pageName,
  onDeleteProduct = noop,
  updateProductQuantity = noop,
  onSaveNote,
  zoomIcon,
  customPromptElement,
  productCardHeaderBottomText,
  productCardIndex = 0,
  enableQuantityUpdate = true,
  showProductReviews,
  showQuantityDetail = true,
  showTotalPriceInline,
  readOnly,
  customTopRightHeaderComponent = null,
  isDisableFooterHoverEffect = false,
  isDisableBrandName = false,
  isNoteDisable,
  callToActionButton = null,
  priceOverrideComponent = null,
  hasAuthorization = true,
  hasPriceAuthorization = true,
  showProductSavingsInfoLabel: showProductSavingsInfoLabelFromProps = true,
  showPriceSavingWithLineThrough:
    showPriceSavingWithLineThroughFromProps = true,
  showBulkBuy: showBulkBuyFromProps = true,
  enableInstrumentation,
  richRelevanceUrl = '',
  imageSize = '144',
  imageFluid = false,
  overflowInListView = false,
  'data-test': dataTest = 'productCardItem',
  showProductIconsList,
  showProductQuantityDetail,
  ...rest
}) => {
  const { productHeader, productBody, productFooter, productCommon } =
    formatProductProps(product, {
      language,
      showContractIcon,
      pageName,
      callingLocation,
      enableInstrumentation,
    })

  const {
    productCode,
    minimumQuantity = PRODUCT_MINIMUM_QUANTITY,
    isAssembly,
    isSpecialDelivery,
    isPromotional,
    isShipSeparately,
    isCatalog,
    onSaleEndDate,
    salesMultiple,
    orderProductId,
    isMarketPrice,
    isRestricted,
    isGiveAway,
    isFreeGiveAway,
  } = productCommon
  const { deliveryMessage, stockMessage } = productFooter
  const tooltipId = `${productCode?.toLowerCase()}-see-available`
  const isProductChecked = useRef(false)
  const productQuantity = useRef(
    minimumQuantity || DEFAULT_ADD_TO_CART_QUANTITY
  )
  const [isHovered, setIsHovered] = useState(false)

  function handleOnMouseOver() {
    setIsHovered(true)
  }

  function handleOnMouseLeave() {
    setIsHovered(false)
  }

  const checkBoxHandler = useCallback(
    (isChecked, productCode, productQuantity, minimumQuantity) => {
      isProductChecked.current = isChecked
      checkboxChangeHandler(
        isChecked,
        productCode,
        productQuantity.current,
        minimumQuantity
      )
    },
    [checkboxChangeHandler]
  )

  const handleQuantityChange = (updatedQuantity) => {
    productQuantity.current = updatedQuantity
    if (isProductChecked.current) {
      checkboxChangeHandler(
        isProductChecked.current,
        productCode,
        updatedQuantity,
        minimumQuantity
      )
    }
  }

  const topSectionSettings = useMemo(
    () => ({ showYourPriceFlag, showContractCareOf, showContractIcon }),
    [showContractCareOf, showContractIcon, showYourPriceFlag]
  )

  const productCardClasses = classNames(
    styles.productCard,
    styles[view],
    className,
    {
      [styles.gridViewSmContent]: !showYourPriceFlag,
      [styles.overflowInListView]: overflowInListView,
    }
  )
  const isGridView = view === GRID_VIEW
  const productFooterClasses = classNames(
    styles.productFooterWrapper,
    styles[view],
    {
      [styles.disableFooterHover]: isDisableFooterHoverEffect,
      [styles.footerOverlayEffect]: isGridView && isHovered,
    }
  )
  const productFooterWrapper = classNames(styles.productFooter, styles[view], {
    [`${styles.gridViewSmPrice}`]: view === GRID_VIEW_SM,
  })

  const { tieredPricing, isFlyer } = productCommon
  const showBulkBuy = getBulkBuyShow(
    tieredPricing,
    isFlyer,
    showBulkBuyFromProps
  )
  const {
    saving,
    basePrice,
    sellPrice,
    marketPrice,
    listPrice,
    isDropShip,
    qualifierType,
    backOrderQuantity,
    price,
    productPrice,
    customUnitOfMeasures,
    finalSellPrice,
    showSubTotal,
    unitSellPrice,
    quantity,
    combinedLinePrice,
    containsPromotion,
    productLinkUrl,
    fees,
    ecoFeeIndex,
    tariffFeeIndex,
    handlingFees,
    savingsAsPercentage,
    calculatedSavings,
    calculatedTotalPrice,
  } = productFooter
  const hasCalculatedSavings = saving || basePrice - sellPrice
  const hasSavings = hasCalculatedSavings > 0
  const showPriceAndHasSavings = showYourPriceFlag && hasSavings
  const isGridViewSm = view === GRID_VIEW_SM
  const priceIsZero = productFooter.sellPrice === 0

  const showProductSavingsInfoLabel =
    showPriceAndHasSavings &&
    !isGridViewSm &&
    showProductSavingsInfoLabelFromProps

  const showPriceSavingWithLineThrough =
    showPriceAndHasSavings &&
    !priceIsZero &&
    !isGridViewSm &&
    showPriceSavingWithLineThroughFromProps

  const { boxProps } = splitBoxProps(rest)

  const { cardProps: extractedCardProps } = splitCardProps(rest)
  const cardProps = {
    ...boxProps,
    ...extractedCardProps,
  }

  const handleOnBlurCardFooter = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      handleOnMouseLeave(event)
    }
  }
  const accessibilityPropsWhenGrid = isGridView
    ? {
        tabIndex: '0',
        role: 'button',
      }
    : {}
  const {
    showQuantityUpdateInput = false,
    setIsDisableActionButtons = () => {},
  } = rest
  const showCustomUnitOfMeasure = view.includes('list')

  const showAddToCartButton = !isRestricted && addToCartEnable
  const isLargeView = view === LIST_VIEW_LARGE
  const isLargeOrMore =
    view === LIST_VIEW_LARGE || view === LIST_VIEW_EXTRA_LARGE
  const showQuantityUpdateBox = showQuantityUpdateInput || isLargeOrMore
  const showTotalPrice = showYourPriceFlag && !showQuantityUpdateInput
  const showShippingQuantity = !isDropShip
  const showBackOrderedText = backOrderQuantity > NO_BACK_ORDERED && !isDropShip

  const { secondaryPrice, secondaryPriceText } = getPriceForFooter(
    showSecondaryPrice,
    isMarketPrice,
    marketPrice,
    listPrice,
    language
  )
  const canShowSecondaryPrice =
    showYourPriceFlag &&
    (isLargeView || isLargeOrMore) &&
    showSecondaryPrice &&
    secondaryPrice !== ZERO
  const handleOnClickBulkBuy = useCallback(() => {
    redirectToProductLink(productLinkUrl)
  }, [productLinkUrl])
  const productListPriceContainer = classNames(
    styles.productFooterInfo,
    showTotalPriceInline ? styles.noPaddingBottom : ''
  )
  const productListViewContainer = classNames(styles.productListViewContainer, {
    [`${styles[view]}`]: view,
  })
  const productTotalPriceContainer = classNames(
    styles.productFooterInfo,
    showTotalPriceInline ? styles.noPaddingBottom : ''
  )
  const isNotSmallGridView = view !== GRID_VIEW_SM
  //product card header start
  const { shortDescription, brand, imageUrl } = productHeader
  const altText = shortDescription || messages[language].productImage
  const showDeleteButton = isDelete && qualifierType !== FREE_GIVE_AWAY
  const isListView = LIST_VIEW === view
  const showGiveAwayInListView = isListView && isFreeGiveAway
  const showGiveAwayInLargeView = isLargeView && isFreeGiveAway
  const showFreeGiveAwayLabel =
    showGiveAwayInListView || showGiveAwayInLargeView

  function handleDeleteProduct() {
    onDeleteProduct(orderProductId)
  }
  const handleCheckboxOnChange = (e) => {
    return checkBoxHandler(
      e.target.checked,
      productCode,
      productQuantity,
      minimumQuantity
    )
  }
  //product card header end
  return (
    <>
      <Card
        className={productCardClasses}
        {...cardProps}
        onMouseEnter={handleOnMouseOver}
        onMouseLeave={handleOnMouseLeave}
        borderColor={showHighlight && highlightColor}
        data-test={dataTest}
      >
        <ProductCardHeader view={view}>
          <div className={styles.productHeaderActionsContainer}>
            {showCheckbox ? (
              <div className={styles.checkBoxWrapper}>
                <Checkbox
                  onChange={handleCheckboxOnChange}
                  id={`checkbox-${productCode}`}
                  name={`checkbox-${productCode}`}
                  space="m0"
                  checked={getCheckedValue(selectedProducts, productCode)}
                />
              </div>
            ) : null}
            {showBulkBuy && isGridView ? (
              <BulkBuyButton
                className={styles.bulkBuyButton}
                onClick={handleOnClickBulkBuy}
                language={language}
              />
            ) : null}

            {zoomIcon ? (
              <div className={styles.zoomWrapper}>
                <ProductQuickViewLoader
                  language={language}
                  isLoggedIn={isLoggedIn}
                  showQuickViewAddToCart={showQuickViewAddToCart}
                  productCode={product.ProductCode}
                  settings={topSectionSettings}
                  pageName={pageName}
                  productLinkUrl={productLinkUrl}
                  addToCartHandler={addToCartHandler}
                  quickViewButton={
                    <Button
                      variant="link"
                      icon="zoom"
                      title={messages[language].zoomBtnText}
                      type="button"
                      data-test="quick-view-button"
                    />
                  }
                  product={product}
                />
              </div>
            ) : null}
            {customTopRightHeaderComponent}
          </div>
          {imageUrl ? (
            <ProductCardLink
              href={readOnly ? null : productLinkUrl}
              product={product}
              readOnly={readOnly}
              aria-label={altText}
              role="link"
            >
              <Image
                src={imageUrl}
                alt={altText}
                title={brand}
                data-test="product-card-header-image"
                fluid={imageFluid}
                height={imageSize}
                width={imageSize}
              />
            </ProductCardLink>
          ) : null}
          {productCardHeaderBottomText}
          {showFreeGiveAwayLabel ? (
            <div className={styles.promoLabel}>
              <InfoLabel
                variant="primary"
                variantText="smText"
                text={messages[language].freeGiveawayItemLabel}
              />
            </div>
          ) : null}

          {showDeleteButton ? (
            <div className={styles.isDelete}>
              <Button
                variant="link"
                icon="delete"
                data-test="product-hard-header-delete-product"
                title={messages[language].deleteBtnText}
                onClick={handleDeleteProduct}
              />
            </div>
          ) : null}
        </ProductCardHeader>

        <ProductCardBody view={view}>
          <ProductHeading
            language={language}
            productLink={productCode}
            title={productBody.productTitle}
            purifiedHtmlString={productBody.purifiedHtmlString}
            variantText={variantText}
            fontWeight={fontWeight}
            view={view}
            readOnly={readOnly}
            href={readOnly ? null : productBody.productLinkUrl}
            product={product}
            index={productCardIndex}
          />
          {isLoggedIn && showProductReviews ? (
            <Rating
              id={productCode}
              readOnly={readOnly}
              language={language}
              name={RATING}
              editing={IS_RATING_EDIT}
              value={productBody.reviewRatings}
              review={productBody.reviewCount}
            />
          ) : null}
          {isNotSmallGridView ? (
            <ProductContent
              language={language}
              productCode={productCode}
              view={view}
              showProductIconsList={showProductIconsList}
              showContractCareOf={showContractCareOf}
              isContractIconSetting={showContractIcon}
              showProductStatus={showProductStatus}
              isNote={isNote}
              onSaveNote={onSaveNote}
              isDisableBrandName={isDisableBrandName}
              customPromptElement={customPromptElement}
              isNoteDisable={isNoteDisable}
              isDisableFooterHoverEffect={isDisableFooterHoverEffect}
              {...productCommon}
              {...rest}
              {...productBody}
            />
          ) : null}
        </ProductCardBody>
        <Box
          className={productFooterClasses}
          onFocus={handleOnMouseOver}
          onBlur={handleOnBlurCardFooter}
          {...accessibilityPropsWhenGrid}
        >
          <ProductCardFooter>
            <Box className={productFooterWrapper}>
              {!isDisableFooterHoverEffect && isGridView && (
                <Box className={styles.productFooterInfo}>
                  <ProductUtilityIcons
                    language={language}
                    view={view}
                    isContract={productCommon.showContractIcon}
                    isFlyer={isFlyer}
                    isAssembly={isAssembly}
                    isSpecialDelivery={isSpecialDelivery}
                    isPromotional={isPromotional}
                    isShipSeparately={isShipSeparately}
                    isMarketPrice={isMarketPrice}
                    isCatalog={isCatalog}
                    isRestricted={isRestricted}
                    onSaleEndDate={onSaleEndDate}
                    isContractIconSetting={showContractIcon}
                    isHovered={isHovered}
                  />
                </Box>
              )}

              {isLoggedIn ? (
                hasAuthorization || hasPriceAuthorization ? (
                  <>
                    <ProductAvailability
                      show={isHovered && isGridView}
                      language={language}
                      shortStockMessage={stockMessage}
                      stockMessage={stockMessage}
                      deliveryMessage={deliveryMessage}
                      showTooltipInfo={isGridView}
                      tooltipId={tooltipId}
                      className={styles.w100}
                      isDisableFooterHoverEffect={isDisableFooterHoverEffect}
                    />
                    <Box className={productListPriceContainer}>
                      <>
                        {showYourPriceFlag ? (
                          <Box className={productListViewContainer}>
                            <ProductListViewPrice
                              language={language}
                              showCustomUnitOfMeasure={showCustomUnitOfMeasure}
                              showProductSavingsInfoLabel={
                                showProductSavingsInfoLabel
                              }
                              showPriceSavingWithLineThrough={
                                showPriceSavingWithLineThrough
                              }
                              qualifierType={qualifierType}
                              price={sellPrice}
                              showPrice={showYourPriceFlag}
                              infoTextVariant="smText"
                              regularPriceText={
                                messages[language].regularPriceText
                              }
                              regularPrice={price}
                              productPrice={productPrice}
                              customUnitOfMeasures={customUnitOfMeasures}
                              view={view}
                              feetoDisplay={fees}
                              finalSellPrice={finalSellPrice}
                              productCode={productCode}
                              showSubTotal={showSubTotal}
                              saving={saving}
                              unitSellPrice={unitSellPrice}
                              basePrice={basePrice}
                              productCardIndex={productCardIndex}
                              quantity={quantity}
                              priceOverrideComponent={priceOverrideComponent}
                              ecoFeeIndex={ecoFeeIndex}
                              tariffFeeIndex={tariffFeeIndex}
                              handlingFees={handlingFees}
                              savingsAsPercentage={savingsAsPercentage}
                              calculatedTotalPrice={calculatedTotalPrice}
                            />
                          </Box>
                        ) : null}

                        {!!canShowSecondaryPrice && (
                          <ProductSecondaryPrice
                            language={language}
                            secondaryPriceText={secondaryPriceText}
                            secondaryPrice={secondaryPrice}
                          />
                        )}
                      </>
                      {showBulkBuy && !isGridView ? (
                        <BulkBuyButton
                          className={styles.bulkBuy}
                          onClick={handleOnClickBulkBuy}
                          language={language}
                        />
                      ) : null}
                      {!isLargeView && (
                        <Box
                          className={styles.addToCart}
                          data-product-footer={`${isHovered ? '' : 'hidden'}`}
                        >
                          {showAddToCartButton ? (
                            <AddToCartButton
                              language={language}
                              defaultQty={productFooter.quantity}
                              onAddToCartResponse={addToCartHandler}
                              productCode={productCode}
                              salesMultiple={salesMultiple}
                              onQuantityChange={handleQuantityChange}
                              callingLocation={callingLocation}
                              inputAriaLabel={messages[language].quantity}
                              richRelevanceUrl={richRelevanceUrl}
                              index={productCardIndex}
                              minimumOrderQuantity={minimumQuantity}
                            />
                          ) : (
                            isRestricted && (
                              <Box className={styles.restrictedIcon}>
                                <ProductUtilityIcons
                                  language={language}
                                  view={view}
                                  isRestricted={isRestricted}
                                  isContract={showContractIcon}
                                  onSaleEndDate={onSaleEndDate}
                                  isContractIconSetting={showContractIcon}
                                />
                                <Text
                                  text={messages[language].restrictedText}
                                  color="primary"
                                  variant="mdText"
                                  fontWeight="medium"
                                  data-test="restricted"
                                />
                              </Box>
                            )
                          )}
                        </Box>
                      )}
                    </Box>
                    {showQuantityUpdateBox ? (
                      <>
                        {showQuantityDetail ? (
                          <Box className={styles.productFooterInfo}>
                            <ProductQuantityDetail
                              id={orderProductId}
                              language={language}
                              showBackOrderedText={showBackOrderedText}
                              disableQuantityField={
                                isGiveAway || !enableQuantityUpdate
                              }
                              quantity={productFooter.quantity}
                              showShippingQuantity={showShippingQuantity}
                              updateProductQuantity={updateProductQuantity}
                              onDeleteProduct={onDeleteProduct}
                              backOrderQuantity={backOrderQuantity}
                              showProductQuantityDetail={
                                showProductQuantityDetail
                              }
                              setIsDisableActionButtons={
                                setIsDisableActionButtons
                              }
                              pageName={pageName}
                              minimumOrderQuantity={minimumQuantity}
                            />
                          </Box>
                        ) : null}
                        {showTotalPrice ? (
                          <Box className={productTotalPriceContainer}>
                            <Box className={styles.productListViewContainer}>
                              <ProductTotalPrice
                                language={language}
                                qualifierType={qualifierType}
                                view={view}
                                showTotalPriceInline={showTotalPriceInline}
                                finalSellPrice={finalSellPrice}
                                combinedLinePrice={combinedLinePrice}
                                quantity={productFooter.quantity}
                                saving={saving}
                                isFlyer={isFlyer}
                                containsPromotion={containsPromotion}
                                calculatedSavings={calculatedSavings}
                              />
                            </Box>
                          </Box>
                        ) : null}
                      </>
                    ) : null}
                    {switchProductAmountInfo}
                  </>
                ) : (
                  messages[language].priceUnavailable
                )
              ) : (
                <Box
                  className={`${styles.productFooterInfo} ${styles.loginInfo}`}
                >
                  <Box className={styles.alignItem}>
                    <Text
                      text={messages[language].beforeLoginFooterText}
                      variant="mdText"
                      color="secondary"
                      data-test="price-text"
                    />
                  </Box>
                </Box>
              )}
              {callToActionButton}
            </Box>
          </ProductCardFooter>
        </Box>
      </Card>
      {deliveryMessage ? (
        <ProductAvailabilityTooltip
          tooltipId={tooltipId}
          stockMessage={stockMessage}
          deliveryMessage={deliveryMessage}
        />
      ) : null}
    </>
  )
}

ProductCard.defaultProps = {
  text: '',
  IsPromotional: false,
  product: {
    LineNote: '',
  },
}

// Props Validation
ProductCard.propTypes = productCardPropTypes

export default memo(ProductCard)
