import PropTypes from 'prop-types'
import Price from 'shared/Price/Price'
import {
  FREE_GIVE_AWAY,
  GRID_VIEW,
  GRID_VIEW_SM,
  LIST_VIEW,
  LIST_VIEW_EXTRA_LARGE,
  LIST_VIEW_LARGE,
  TABLE_VIEW,
} from 'shared/ProductCard/ProductCardConstants'
import ProductInfoLabel from 'shared/ProductCard/ProductCardFooter/ProductInfoLabel'
import ProductPriceToolTips from 'shared/ProductCard/ProductPriceToolTips'
import Text from 'shared/Text'
import ProductDiscountWithLineThrough from '../ProductDiscountWithLineThrough/ProductDiscountWithLineThrough'
import messages from './messages'
import styles from './ProductListViewPrice.module.scss'

/**
 * Name: ProductListViewPrice
 * Desc: render product price
 * @param {string} language
 * @param {number} price
 * @param {string} infoTextVariant
 * @param {string} regularPriceText
 * @param {number} regularPrice
 * @param {string} customUnitOfMeasures
 * @param {string} view
 * @param {array} feetoDisplay
 * @param {number} finalSellPrice
 * @param {string} productCode
 * @param {bool} showSubTotal
 * @param {number} saving
 * @param {number} unitSellPrice
 * @param {number} basePrice
 * @param {number} qualifierType
 * @param {bool} showPrice
 * @param {number} quantity
 */

const ProductListViewPrice = ({
  language,
  price,
  infoTextVariant,
  regularPriceText,
  regularPrice,
  customUnitOfMeasures,
  view,
  feetoDisplay = [],
  finalSellPrice,
  showSubTotal,
  saving,
  unitSellPrice,
  basePrice,
  qualifierType,
  showPrice,
  productCode,
  productCardIndex,
  quantity,
  priceOverrideComponent,
  productPrice,
  showCustomUnitOfMeasure,
  showProductSavingsInfoLabel,
  showPriceSavingWithLineThrough,
  ecoFeeIndex,
  tariffFeeIndex,
  handlingFees,
  savingsAsPercentage,
  calculatedTotalPrice,
}) => {
  saving = saving || basePrice - price
  const showProductSale = saving > 0

  const toolTipPositionSm = view === GRID_VIEW_SM ? styles.toolTipPosition : ''

  const showRegularPrice = showPrice && showProductSale && view === GRID_VIEW

  const lineThrough = !!(
    qualifierType === FREE_GIVE_AWAY &&
    (view === LIST_VIEW_LARGE || view === LIST_VIEW)
  )

  const showQuantity = view === LIST_VIEW_EXTRA_LARGE

  const savingsComponent =
    saving < 5 ? (
      savingsAsPercentage
    ) : (
      <Price language={language}>{saving}</Price>
    )
  return (
    <>
      {showProductSavingsInfoLabel ? (
        <ProductInfoLabel variant={infoTextVariant} language={language}>
          {savingsComponent} {`${messages[language].offLabel}`}
        </ProductInfoLabel>
      ) : null}

      <span className={`${styles.headingFlex} ${styles[view]}`}>
        {showRegularPrice && (
          <Text
            text={<Price language={language}>{regularPrice}</Price>}
            color="secondary"
            lineThrough={true}
            className={styles.originalPrice}
          />
        )}
        {showPrice ? (
          <div className={styles.unitWrapper}>
            {priceOverrideComponent ||
              (view === TABLE_VIEW ? (
                <Price language={language}>{productPrice}</Price>
              ) : (
                <Text
                  text={<Price language={language}>{productPrice}</Price>}
                  color="secondary"
                  lineThrough={lineThrough}
                  className={styles.savingPrice}
                  data-test="price-per-unit"
                />
              ))}
            {showCustomUnitOfMeasure ? (
              <ProductCustomUnitOfMeasures text={customUnitOfMeasures} />
            ) : null}
            {showQuantity ? (
              <div className={styles.productQuantity}>
                <Text text={`${messages[language].quantity}:`} />
                <Text
                  text={quantity}
                  color="primary"
                  data-test="product-list-view-quantity"
                />
              </div>
            ) : null}
          </div>
        ) : null}
        <div className={`${styles.toolTipInfo} ${toolTipPositionSm}`}>
          {showPrice && feetoDisplay && !!feetoDisplay.length && (
            <sup>
              {feetoDisplay && !!feetoDisplay.length && (
                <ProductPriceToolTips
                  language={language}
                  name={`productPriceTips_${productCode}_${productCardIndex}`}
                  price={price}
                  view={view}
                  totalPrice={finalSellPrice}
                  productHandling={handlingFees}
                  savingPrice={showProductSale}
                  showSubTotal={showSubTotal && handlingFees.length > 0}
                  ecoFee={ecoFeeIndex}
                  ecoFeeObj={
                    ecoFeeIndex !== -1 ? feetoDisplay[ecoFeeIndex] : {}
                  }
                  tariffFee={tariffFeeIndex}
                  tariffFeeObj={
                    tariffFeeIndex !== -1 ? feetoDisplay[tariffFeeIndex] : {}
                  }
                  saving={saving}
                  unitSellPrice={unitSellPrice}
                  basePrice={basePrice}
                  calculatedTotalPrice={calculatedTotalPrice}
                />
              )}
            </sup>
          )}
          {(view === GRID_VIEW || view === GRID_VIEW_SM) && (
            <ProductCustomUnitOfMeasures text={customUnitOfMeasures} />
          )}
        </div>
      </span>

      {showPriceSavingWithLineThrough && (
        <ProductDiscountWithLineThrough
          regularPriceText={regularPriceText}
          language={language}
          regularPrice={regularPrice}
        />
      )}
    </>
  )
}

// Props Validation
ProductListViewPrice.propTypes = {
  language: PropTypes.string.isRequired,
  price: PropTypes.number,
  infoTextVariant: PropTypes.string,
  regularPriceText: PropTypes.string,
  regularPrice: PropTypes.number,
  customUnitOfMeasures: PropTypes.string,
  feetoDisplay: PropTypes.array,
  showCustomUnitOfMeasure: PropTypes.bool,
  showProductSavingsInfoLabel: PropTypes.bool,
  showPriceSavingWithLineThrough: PropTypes.bool,
  finalSellPrice: PropTypes.number,
  showSubTotal: PropTypes.bool,
  saving: PropTypes.number,
  unitSellPrice: PropTypes.number,
  basePrice: PropTypes.number,
  qualifierType: PropTypes.number,
  view: PropTypes.string,
  showPrice: PropTypes.bool,
  productCode: PropTypes.string,
  productCardIndex: PropTypes.number,
  quantity: PropTypes.number,
  priceOverrideComponent: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  productPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handlingFees: PropTypes.array,
  ecoFeeIndex: PropTypes.number,
  tariffFeeIndex: PropTypes.number,
  savingsAsPercentage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  calculatedTotalPrice: PropTypes.number,
}

export default ProductListViewPrice

function ProductCustomUnitOfMeasures({ text }) {
  return (
    <div className={styles.measureUnit}>
      <Text text={text} />
    </div>
  )
}

ProductCustomUnitOfMeasures.propTypes = {
  text: PropTypes.string,
}
