import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import Price from 'shared/Price/Price'
import {
  FREE_GIVE_AWAY,
  LIST_VIEW_LARGE,
} from 'shared/ProductCard/ProductCardConstants'
import ProductSecondaryPrice from 'shared/ProductCard/ProductCardFooter/ProductSecondaryPrice'
import Text from 'shared/Text'
import messages from './messages.js'
import styles from './ProductTotalPrice.module.scss'
/**
 * Name: ProductTotalPrice
 * Desc: Render product total price
 * @param {string} language
 * @param {number} qualifierType
 * @param {string} view
 * @param {number} finalSellPrice
 * @param {number} combinedLinePrice
 * @param {number} saving
 * @param {number} quantity
 * @param {bool} isFlyer
 * @param {bool} canShowSecondaryPrice
 * @param {bool} secondaryPriceText
 * @param {bool} secondaryPrice
 */
const ProductTotalPrice = ({
  language,
  qualifierType,
  view,
  finalSellPrice,
  combinedLinePrice,
  saving,
  isFlyer,
  showTotalPriceInline,
  canShowSecondaryPrice,
  secondaryPriceText,
  secondaryPrice,
  containsPromotion,
  calculatedSavings,
}) => {
  const isGiveAway = qualifierType === FREE_GIVE_AWAY
  const isLargeListView = view === LIST_VIEW_LARGE
  const isGiveAwayAndIsLargeListView = isGiveAway && isLargeListView
  const isPromotionNotFlyerAndHasSaving =
    containsPromotion && !isFlyer && !!saving

  const price = isGiveAwayAndIsLargeListView
    ? finalSellPrice
    : isPromotionNotFlyerAndHasSaving
    ? calculatedSavings
    : combinedLinePrice

  const directionClass = !showTotalPriceInline ? styles.column : styles.row
  const productTotalPriceContainer = classNames(styles.root, directionClass, {
    [styles.alignEnd]: showTotalPriceInline,
  })

  const productTotalPriceClasses = classNames(styles.productTotalPrice, {
    [styles.reverseOrder]: showTotalPriceInline,
  })

  const textStyling = classNames(styles.textStyling, {
    [styles.mr10]: showTotalPriceInline,
    [styles.lineHeight]: showTotalPriceInline,
  })

  const label = messages[language].totalPrice

  const headingType = classNames(styles.heading, {
    [styles.priceSm]: !showTotalPriceInline,
    [styles.priceMd]: showTotalPriceInline,
  })
  const Component = !showTotalPriceInline ? Fragment : 'div'
  const props = {
    ...(showTotalPriceInline && { className: productTotalPriceContainer }),
  }
  return (
    <Component {...props}>
      <div className={productTotalPriceClasses}>
        {!!canShowSecondaryPrice && (
          <ProductSecondaryPrice
            language={language}
            secondaryPriceText={secondaryPriceText}
            secondaryPrice={secondaryPrice}
          />
        )}
        <Text
          text={<Price language={language}>{price}</Price>}
          color="secondary"
          className={headingType}
        />
      </div>
      <Text text={label} variant="smText" className={textStyling} />
    </Component>
  )
}

// Default Props
ProductTotalPrice.defaultProps = {
  showTotalPriceLabel: true,
  showItemTotalLabel: false,
}

// Props Validation
ProductTotalPrice.propTypes = {
  language: PropTypes.string.isRequired,
  qualifierType: PropTypes.number,
  view: PropTypes.string,
  finalSellPrice: PropTypes.number,
  combinedLinePrice: PropTypes.number,
  saving: PropTypes.number,
  quantity: PropTypes.number,
  isFlyer: PropTypes.bool,
  showTotalPriceInline: PropTypes.bool,
  canShowSecondaryPrice: PropTypes.bool,
  containsPromotion: PropTypes.bool,
  secondaryPriceText: PropTypes.bool,
  secondaryPrice: PropTypes.bool,
  calculatedSavings: PropTypes.number,
}

export default ProductTotalPrice
