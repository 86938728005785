import PropTypes from 'prop-types'
import FormatHtmlSpan from 'shared/FormatHtmlSpan'
import Text from '~/components/shared/Text'
import Tooltip from '~/components/shared/Tooltip'
import styles from './ProductAvailabilityTooltip.module.scss'

function ProductAvailabilityTooltip({
  tooltipId,
  stockMessage,
  deliveryMessage,
}) {
  return (
    <Tooltip
      place="top"
      effect="solid"
      id={tooltipId}
      className={styles.tooltip}
    >
      <Text
        text={stockMessage}
        variant="smText"
        color="success"
        fontWeight="strong"
      />{' '}
      <Text text={<FormatHtmlSpan text={deliveryMessage} />} variant="smText" />
    </Tooltip>
  )
}

ProductAvailabilityTooltip.propTypes = {
  deliveryMessage: PropTypes.any,
  stockMessage: PropTypes.any,
  tooltipId: PropTypes.any,
}

export default ProductAvailabilityTooltip
