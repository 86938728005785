export default {
  en: {
    beforeLoginFooterText: 'Login for pricing and availability information',
    regularPriceText: 'Reg.',
    quantity: 'Quantity:',
    original: 'Original item unit price',
    suggested: 'Suggested item unit price',
    totalSaving: 'Total savings',
    each: 'each',
    listPrice: 'List price',
    ship: 'Ship:',
    backOrder: 'Backorder:',
    restrictedText: 'Restricted',
    marketPrice: 'Market Price',
    priceUnavailable:
      'Pricing history for this order is unavailable at this time.',
    bulkBuy: 'Bulk Buy',
    deleteBtnText: 'Delete',
    freeGiveawayItemLabel: 'Free Giveaway Item',
    productImage: 'Product Image',
    zoomBtnText: 'Quick View',
  },
  fr: {
    beforeLoginFooterText:
      'Connectez-vous pour connaître les prix et la disponibilité',
    regularPriceText: 'Rég. ',
    quantity: 'Quantité',
    original: `Prix unitaire de l'article original`,
    suggested: `Prix unitaire de l'article suggéré`,
    totalSaving: `Épargne totale`,
    each: 'each',
    listPrice: 'Prix détail',
    restrictedText: 'Restreint',
    marketPrice: 'Prix ​​du marché',
    priceUnavailable:
      "L'historique de prix de cette commande est indisponible pour le moment.",
    bulkBuy: 'Achat en gros',
    deleteBtnText: 'Delete',
    freeGiveawayItemLabel: 'Article cadeau gratuit',
    productImage: 'Image de produit',
    zoomBtnText: `aperçu rapide`,
  },
}
