import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './ProductCardHeader.module.scss'

/**
 * Name: ProductCardHeader
 * Desc: Render Product card header
 * @param {string} view
 * @param {node} children
 */

const ProductCardHeader = ({ view, children }) => {
  const productHeader = classNames(styles.productHeader, styles[view])
  return <div className={productHeader}>{children}</div>
}

// Props Validation
ProductCardHeader.propTypes = {
  children: PropTypes.node,
  view: PropTypes.string,
}
export default ProductCardHeader
