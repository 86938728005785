import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { GRID_VIEW } from 'shared/ProductCard/ProductCardConstants'
import ProductCardLink from '../../ProductCardHeader/ProductCardLink'
import styles from './ProductHeading.module.scss'

/**
 * Name: ProductHeading
 * Desc: Render Product heading
 * @param {string} title
 * @param {string} variantText
 * @param {string} fontWeight
 * @param {string} href
 * @param {bool} readOnly
 */

const ProductHeading = ({
  title,
  variantText,
  fontWeight,
  view,
  readOnly,
  href,
  product,
  index,
  purifiedHtmlString,
}) => {
  const viewClass = styles[view]
  const productHeading = classNames(styles.productHeading, {
    [viewClass]: view !== GRID_VIEW && !!viewClass,
  })
  const titleAsHtml = useMemo(
    () => <span dangerouslySetInnerHTML={{ __html: title }} />,
    [title]
  )

  const productLinkProps = {
    href,
    dataTest: 'product-detail-url',
    color: 'secondary',
    variant: variantText,
    fontWeight,
    readOnly,
    'aria-label': purifiedHtmlString,
    role: 'link',
    product,
    index,
  }

  return (
    <div className={productHeading}>
      <ProductCardLink {...productLinkProps}>{titleAsHtml}</ProductCardLink>
    </div>
  )
}

// Default props

ProductHeading.defaultProps = {
  variantText: 'lgText',
  fontWeight: 'strong',
  title: '',
}

// Props Validation
ProductHeading.propTypes = {
  language: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  variantText: PropTypes.string,
  fontWeight: PropTypes.string,
  view: PropTypes.string,
  readOnly: PropTypes.bool,
  href: PropTypes.string,
  product: PropTypes.object,
  index: PropTypes.number,
  purifiedHtmlString: PropTypes.string,
}

export default ProductHeading
