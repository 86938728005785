import classNames from 'classnames'
import PropTypes from 'prop-types'
import LabelText from 'shared/LabelText'
import ProductQuantityField from 'shared/ProductCard/ProductCardFooter/ProductQuantityField'
import Text from 'shared/Text'
import Flex from '~/components/shared/Flex/Flex.js'
import messages from './messages.js'
import styles from './ProductQuantityDetail.module.scss'
import isNan from 'lodash/isNaN'

/**
 * Name: ProductQuantityDetail
 * Desc: Render product quantity information
 * @param {string} language
 * @param {number} disableQuantityField
 * @param {number} quantity
 * @param {string} orderProductId
 * @param {func} updateProductQuantity
 * @param {func} onDeleteProduct
 * @param {number} backOrderQuantity
 * @param {bool} enableQuantityUpdate
 */
const ProductQuantityDetail = ({
  id,
  language,
  showBackOrderedText,
  disableQuantityField,
  showShippingQuantity,
  quantity,
  updateProductQuantity,
  onDeleteProduct,
  backOrderQuantity,
  setIsDisableActionButtons,
  pageName,
  minimumOrderQuantity,
  showProductQuantityDetail = true,
}) => {
  const productQuantityClasses = classNames(styles.inputField, {
    [styles.inputFieldGray]: disableQuantityField,
  })
  const calculatedQuantity = quantity - backOrderQuantity
  const shippingQuantity = !isNan(calculatedQuantity) ? calculatedQuantity : ''

  return (
    <>
      <div className={styles.productContentTop}>
        <Flex className={styles.inputFieldWrapper}>
          <div className={productQuantityClasses}>
            <ProductQuantityField
              id={id}
              quantity={quantity}
              updateProductQuantity={updateProductQuantity}
              onDeleteProduct={onDeleteProduct}
              disabled={disableQuantityField}
              aria-label={messages[language].quantity}
              setIsDisableActionButtons={setIsDisableActionButtons}
              pageName={pageName}
              language={language}
              minimumOrderQuantity={minimumOrderQuantity}
            />
          </div>
          <Text text={messages[language].quantity} variant="smText" />
        </Flex>
      </div>
      {showProductQuantityDetail ? (
        <div
          className={styles.productQuantityDetailsContentCenter}
          data-test="product-quantity-detail-item"
        >
          {showShippingQuantity ? (
            <ProductQuantityDetailItem
              label={messages[language].ship}
              text={shippingQuantity}
            />
          ) : null}
          {showBackOrderedText ? (
            <ProductQuantityDetailItem
              label={messages[language].backOrder}
              text={backOrderQuantity}
            />
          ) : null}
        </div>
      ) : null}
    </>
  )
}

function ProductQuantityDetailItem({ className, ...props }) {
  const productQtyItemClasses = classNames(
    styles.productQuantityItem,
    className
  )
  return (
    <Flex className={productQtyItemClasses}>
      <LabelText
        {...props}
        variant="smText"
        color="lightGray"
        fontFamilyLabel="md"
      />
    </Flex>
  )
}

ProductQuantityDetailItem.propTypes = {
  className: PropTypes.string,
}

// Props Validation
ProductQuantityDetail.propTypes = {
  id: PropTypes.string,
  language: PropTypes.string.isRequired,
  disableQuantityField: PropTypes.bool,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  updateProductQuantity: PropTypes.func,
  onDeleteProduct: PropTypes.func,
  backOrderQuantity: PropTypes.number,
  showBackOrderedText: PropTypes.bool,
  showProductQuantityDetail: PropTypes.bool,
  showShippingQuantity: PropTypes.bool,
  setIsDisableActionButtons: PropTypes.func,
  pageName: PropTypes.string,
  minimumOrderQuantity: PropTypes.number,
}

export default ProductQuantityDetail
