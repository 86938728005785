import PropTypes from 'prop-types'
import EwayRouterLink from 'shared/EwayRouterLink'
import EwayLink from 'shared/EwayLink'
import { includesProductDetail } from '~/services/utils'
import { handleProductSegment } from '~/services/telemetaryData/productsTileTelemetary/ProductsTileTelemetary'
import { AnalyticsContext } from 'shared/Utils/contexts'
import { useContext } from 'react'

function ProductCardLink({
  children,
  href,
  product,
  index,
  onClick,
  ...props
}) {
  const { segmentLocation = '' } = useContext(AnalyticsContext)
  const Component = includesProductDetail(href) ? EwayRouterLink : EwayLink

  const handleClick = (e) => {
    handleProductSegment({
      product,
      position: index,
      listId: segmentLocation,
    })
    onClick?.(e)
  }

  return (
    <Component onClick={handleClick} {...props} href={href}>
      {children}
    </Component>
  )
}

ProductCardLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  product: PropTypes.object,
  index: PropTypes.number,
  onClick: PropTypes.func,
}

export default ProductCardLink
