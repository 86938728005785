import isNumber from 'lodash/isNumber'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { filterNumbers } from 'services/utils'
import {
  MIN_PRODUCT_QTY,
  QUANTITY_MAX_LENGTH,
} from 'shared/ProductCard/ProductCardConstants'
import { useLoader } from '~/components/shared/CustomHooks/useLoader'
import Flex from '~/components/shared/Flex'
import FormField from '~/components/shared/FormControl/FormField'
import Loader from '~/components/shared/Loader'
import styles from './ProductQuantityField.module.scss'
import { useRouter } from 'next/router'
import { isSubmittedOrderModifyPage } from '~/components/shared/Utils/pageUtils'
import messages from '../ProductQuantityDetail/messages'
import { checkMinimumQuantityAndShowError } from '~/components/shared/AddToCartButton/AddToCartButtonUtils'
import { PRODUCT_MINIMUM_QUANTITY } from '~/components/shared/AddToCartButton/AddToCartButtonConstants'

/**
 * ProductQuantityField
 * Render quantity field

 * @param {string} quantity
 * @param {string} id
 * @param {func} updateProductQuantity
 * @param {func} onDeleteProduct
 * @param {func} disabled
 * @param {string} "aria-label"
 */
const ProductQuantityField = ({
  id,
  quantity: quantityFromProps = MIN_PRODUCT_QTY,
  updateProductQuantity,
  onDeleteProduct,
  disabled,
  'aria-label': inputAriaLabel,
  setIsDisableActionButtons,
  pageName,
  minimumOrderQuantity = PRODUCT_MINIMUM_QUANTITY,
  language,
}) => {
  const translations = messages[language]
  const [quantity, setQuantity] = useState(quantityFromProps || MIN_PRODUCT_QTY)
  const { setPending, setResolved, isLoading } = useLoader()
  const previousValue = useRef()

  const { query = {} } = useRouter()
  const isEditModifyPage = isSubmittedOrderModifyPage({ pageName, query })

  // "useIsMounted" not working in tests, to find out why
  const isMounted = useRef()

  useEffect(() => {
    isMounted.current = true
    previousValue.current = Number(quantityFromProps)
    isEditModifyPage && setQuantity(quantityFromProps)
    return () => {
      isMounted.current = false
    }
  }, [quantityFromProps, isEditModifyPage])

  function handleOnChange(e) {
    setQuantity(filterNumbers(e.target.value))
    isEditModifyPage && setIsDisableActionButtons(true)
  }

  async function handleOnBlur() {
    const numberQuantity = Number(quantity)
    if (!isNumber(numberQuantity) || previousValue.current === numberQuantity) {
      isEditModifyPage && setIsDisableActionButtons(false)
      return
    }
    if (
      checkMinimumQuantityAndShowError({
        numberValue: numberQuantity,
        minimumOrderQuantity,
        message: translations.minimumOrderMsg,
      })
    ) {
      setQuantity(filterNumbers(previousValue.current))
      isEditModifyPage && setIsDisableActionButtons(true)
      return
    }
    let apiResponse = {}
    setPending()
    if (numberQuantity) {
      previousValue.current = numberQuantity
      apiResponse = await updateProductQuantity(id, numberQuantity)
    } else if (quantity !== '' && numberQuantity === 0) {
      apiResponse = await onDeleteProduct(id) // Remove product from cart if qty = 0
      isEditModifyPage && setIsDisableActionButtons(false)
    } else if (quantity === '') {
      setQuantity(MIN_PRODUCT_QTY)
      previousValue.current = MIN_PRODUCT_QTY
      apiResponse = await updateProductQuantity(id, MIN_PRODUCT_QTY)
    }
    if (apiResponse?.isSuccess === false) {
      setQuantity(quantityFromProps)
    }
    if (isMounted.current) {
      setResolved()
    }
  }

  return (
    <Flex align="center" justify="flex-end" className={styles.fieldContainer}>
      <div className={styles.loaderContainer}>
        {isLoading ? <Loader /> : null}
      </div>
      <FormField
        id={id}
        disabled={disabled}
        className={styles.minWidth}
        type="text"
        name="total"
        value={quantity}
        data-test="product-quantity"
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        maxLength={QUANTITY_MAX_LENGTH}
        readOnly={isLoading}
        aria-label={inputAriaLabel}
      />
    </Flex>
  )
}

ProductQuantityField.propTypes = {
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  updateProductQuantity: PropTypes.func,
  onDeleteProduct: PropTypes.func,
  disabled: PropTypes.bool,
  'aria-label': PropTypes.string,
  setIsDisableActionButtons: PropTypes.func,
  pageName: PropTypes.string,
  minimumOrderQuantity: PropTypes.number,
  language: PropTypes.string,
}
export default ProductQuantityField
