import classNames from 'classnames'
import LabelText from 'shared/LabelText'
import {
  GRID_VIEW,
  GRID_VIEW_SM,
} from 'shared/ProductCard/ProductCardConstants'
import { productContentPropTypes } from 'shared/ProductCard/ProductCardUtils/ProductCardPropTypes'
import ProductUtilityIcons from 'shared/ProductCard/ProductUtilityIcons'
import Text from 'shared/Text'
import Box from 'shared/Box'
import Icon from '~/components/shared/Icon'
import InfoLabel from 'shared/InfoLabel'
import ProductAvailability from '~/components/shared/ProductCard/ProductCardSharedComponents/ProductAvailability'
import ProductContentLineNote from './ProductContentLineNote'
import messages from './messages'
import styles from './ProductContent.module.scss'

/**
 * Name: ProductContent
 * Desc: Render product details on card
 * @param {string} language
 * @param {string} productCode
 * @param {bool} showContractCareOf
 * @param {bool} showContractIcon
 * @param {string} productText
 * @param {string} memberPrice
 * @param {bool} isFlyer
 * @param {bool} isAssembly
 * @param {bool} isSpecialDelivery
 * @param {bool} isPromotional
 * @param {bool} isRestricted
 * @param {bool} isCatalog,
 * @param {bool} isMarketPrice
 * @param {bool} isNote
 * @param {bool} isShipSeparately
 * @param {bool} showProductStatus
 * @param {string} view
 * @param {string} deliveryMessage
 * @param {object} promoInfo
 * @param {bool} isSpecialOrder
 * @param {string} stockMessage
 * @param {string} lineNote
 * @param {string} leadTimeMessage
 * @param {func} onSaveNote
 * @param {string} orderProductId
 * @param {element} customPromptElement
 * @param {bool} isDisableBrandName
 * @param {bool} isDisableFooterHoverEffect
 * @param {bool} isNoteDisable
 */

const ProductContent = ({
  language,
  productText,
  memberPrice,
  productCode,
  showContractCareOf,
  showContractIcon,
  isContractIconSetting,
  showProductStatus,
  view,
  isFlyer,
  isAssembly,
  isSpecialDelivery,
  isPromotional,
  isShipSeparately,
  isMarketPrice,
  isCatalog,
  isRestricted,
  deliveryMessage,
  promoInfo = {},
  stockMessage,
  isNote,
  lineNote,
  leadTimeMessage,
  onSaveNote,
  orderProductId,
  customPromptElement,
  onSaleEndDate,
  isDisableBrandName,
  isDisableFooterHoverEffect,
  showProductIconsList = true,
  isNoteDisable,
}) => {
  const viewClass = styles[view]
  const productContent = classNames(styles.productCardBodyContent, {
    [viewClass]: view !== GRID_VIEW && !!viewClass,
  })

  const isGridView = view === GRID_VIEW
  const isGridViewSmall = view === GRID_VIEW_SM
  const isListView = view?.includes('list')
  const { ContainPromo = false, Message = '' } = promoInfo
  return (
    <Box className={productContent}>
      {!isDisableBrandName ? (
        <Box className={styles.productCardText}>
          {!isGridViewSmall && !isGridView ? (
            <LabelText
              data-test="product-brand-text"
              text={productText || ''}
              label={messages[language].brandText}
            />
          ) : (
            <Text text={productText} dataTest="product-brand-text" />
          )}
        </Box>
      ) : null}

      <Box className={styles.productCardText}>
        {!isGridViewSmall && !isGridView ? (
          <LabelText
            data-test="ProductCode"
            text={productCode}
            label={messages[language].itemText}
          />
        ) : (
          <Text text={productCode} dataTest="ProductCode" />
        )}
      </Box>

      {showContractCareOf && !!memberPrice ? (
        <Box className={`${styles.productCardText} ${styles.memberPrice}`}>
          <Text text={memberPrice} data-test="StandingOffer" />
        </Box>
      ) : null}
      {showProductIconsList ? (
        <>
          {!isGridViewSmall ? (
            <Box minH="21px">
              <ProductUtilityIcons
                language={language}
                view={view}
                isContract={showContractIcon}
                isFlyer={isFlyer}
                isAssembly={isAssembly}
                isSpecialDelivery={isSpecialDelivery}
                isPromotional={isPromotional}
                isShipSeparately={isShipSeparately}
                isMarketPrice={isMarketPrice}
                isCatalog={isCatalog}
                isRestricted={isRestricted}
                onSaleEndDate={onSaleEndDate}
                isContractIconSetting={isContractIconSetting}
              />
            </Box>
          ) : null}
        </>
      ) : null}
      {showProductStatus ? (
        <Box className={styles.productContentInfo}>
          {isListView ? (
            <>
              {leadTimeMessage ? (
                <Box className={styles.infoLabelWrapper}>
                  <InfoLabel
                    variant="secondary"
                    variantText="smText"
                    color="secondary"
                    text={leadTimeMessage}
                  />
                </Box>
              ) : null}
              {ContainPromo ? (
                <Box className={styles.purchaseWrapper}>
                  <span className={`${styles.iconWrapper} ${styles.primary}`}>
                    <Icon variant="promo" />
                  </span>
                  <Text text={Message} variant="smText" color="secondary" />
                </Box>
              ) : null}
            </>
          ) : null}
          {!isDisableFooterHoverEffect ? (
            <ProductAvailability
              language={language}
              stockMessage={stockMessage}
              deliveryMessage={deliveryMessage}
              showDeliveryMessage={isListView}
            />
          ) : null}
        </Box>
      ) : null}
      {isNote ? (
        <ProductContentLineNote
          productCode={productCode}
          lineNote={lineNote}
          orderProductId={orderProductId}
          onSaveNote={onSaveNote}
          isNoteDisable={isNoteDisable}
          lineNotePlaceholder={messages[language].lineNotePlaceholder}
        />
      ) : null}
      {customPromptElement}
    </Box>
  )
}

// Default Props
ProductContent.defaultProps = {
  lineNote: '',
  showProductStatus: true,
  isDisableBrandName: false,
}

// Props Validation
ProductContent.propTypes = productContentPropTypes

export default ProductContent
