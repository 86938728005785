import classNames from 'classnames'
import PropTypes from 'prop-types'
import FormatHtmlSpan from 'shared/FormatHtmlSpan'
import Text from 'shared/Text'
import { ENGLISH } from '~/redux/constants'
import messages from './messages'
import styles from './ProductAvailability.module.scss'
import { handleTooltipShowHide } from 'services/utils'
function ProductAvailability({
  show,
  stockMessage,
  shortStockMessage,
  showDeliveryMessage,
  deliveryMessage,
  tooltipId,
  showTooltipInfo,
  language,
  className,
  isDisableFooterHoverEffect,
  ...rest
}) {
  const deliveryMessageContent = <FormatHtmlSpan text={deliveryMessage} />
  const canShowDeliveryMessage = !!deliveryMessage && showDeliveryMessage
  const tooltipLabel = messages[language].tooltipLabel
  const stockMessageShown = shortStockMessage || stockMessage
  const classes = classNames(className, styles.productAvailability, {
    [styles.hide]: !isDisableFooterHoverEffect && !show,
  })

  return (
    <div className={classes} {...rest}>
      {stockMessageShown ? (
        <Text
          text={stockMessageShown}
          variant="smText"
          color="success"
          fontWeight="strong"
        />
      ) : null}
      {canShowDeliveryMessage ? (
        <Text text={deliveryMessageContent} variant="smText" />
      ) : null}
      {deliveryMessage ? (
        <>
          <Text
            className={!showTooltipInfo ? styles.hide : ''}
            variant="xsText"
            cursor="pointer"
            textDecoration
            data-tip
            data-for={tooltipId}
            tabIndex="0"
            aria-describedby={tooltipId}
            onFocus={handleTooltipShowHide}
            onBlur={handleTooltipShowHide}
            role="link"
            aria-label={tooltipLabel}
          >
            {tooltipLabel}
          </Text>
        </>
      ) : null}
    </div>
  )
}

// Default Props
ProductAvailability.defaultProps = {
  show: true,
  showDeliveryMessage: false,
  showTooltipInfo: false,
  language: ENGLISH,
}

ProductAvailability.propTypes = {
  show: PropTypes.bool,
  showDeliveryMessage: PropTypes.bool,
  showTooltipInfo: PropTypes.bool,
  deliveryMessage: PropTypes.string,
  language: PropTypes.string,
  deliveryMessageContent: PropTypes.string,
  stockMessage: PropTypes.string,
  shortStockMessage: PropTypes.string,
  tooltipId: PropTypes.string,
  className: PropTypes.string,
  isDisableFooterHoverEffect: PropTypes.bool,
}

export default ProductAvailability
