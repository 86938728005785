import PropTypes from 'prop-types'

export const productCardPropTypes = {
  language: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  showYourPriceFlag: PropTypes.bool,
  showPriceSavingWithLineThrough: PropTypes.bool,
  showHighlight: PropTypes.bool,
  highlightColor: PropTypes.string,
  showContractCareOf: PropTypes.bool,
  checkboxChangeHandler: PropTypes.func,
  showCheckbox: PropTypes.bool,
  addToCartEnable: PropTypes.bool,
  zoomIcon: PropTypes.bool,
  view: PropTypes.string,
  addToCartHandler: PropTypes.func,
  showContractIcon: PropTypes.bool,
  customUnitOfMeasures: PropTypes.bool,
  variantText: PropTypes.string,
  fontWeight: PropTypes.string,
  isNote: PropTypes.bool,
  selectedProducts: PropTypes.array,
  showSecondaryPrice: PropTypes.number,
  buyerId: PropTypes.string,
  className: PropTypes.string,
  onSaveNote: PropTypes.func,
  product: PropTypes.shape({
    ImageNames: PropTypes.array,
    Description: PropTypes.string,
    Brand: PropTypes.string,
    ProductCode: PropTypes.string,
    FinalSellPrice: PropTypes.number,
    FeetoDisplay: PropTypes.array,
    StandingOfferNumber: PropTypes.string,
    IsFlyer: PropTypes.bool,
    IsAssembly: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    IsSpecialDelivery: PropTypes.bool,
    IsPromotional: PropTypes.bool,
    IsDropShip: PropTypes.bool,
    IsMarketPrice: PropTypes.bool,
    IsCatalog: PropTypes.bool,
    IsRestricted: PropTypes.bool,
    AlphaCommentLine1: PropTypes.string,
    CombinedBasePrice: PropTypes.number,
    CombinedSellPrice: PropTypes.number,
    LineNote: PropTypes.string,
    Quantity: PropTypes.number,
    CombinedMarketPrice: PropTypes.number,
    OrderProductId: PropTypes.string,
    CombinedLinePrice: PropTypes.number,
    QualifierType: PropTypes.number,
    CombinedDetailPrice: PropTypes.number,
    IsPriceReallyWeeFeeCombined: PropTypes.bool,
    IsPriceTariffFeeCombined: PropTypes.bool,
    Saving: PropTypes.number,
    UnitSellPrice: PropTypes.number,
    BasePrice: PropTypes.number,
    QuantityBO: PropTypes.number,
    OrderProductFees: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }),
  callingLocation: PropTypes.object,
  pageName: PropTypes.string,
  deleteProduct: PropTypes.func,
  updateProductQuantity: PropTypes.func,
  showProductStatus: PropTypes.bool,
  isDelete: PropTypes.bool,
  customPromptElement: PropTypes.element,
  isSelectBrand: PropTypes.bool,
  productCardIndex: PropTypes.number,
  showProductReviews: PropTypes.bool,
  productCardHeaderBottomText: PropTypes.node,
  customTopRightHeaderComponent: PropTypes.object,
  isDisableFooterHoverEffect: PropTypes.bool,
  isDisableBrandName: PropTypes.bool,
  callToActionButton: PropTypes.element,
  overflowInListView: PropTypes.bool,
}

export const productCardBodyPropTypes = {
  language: PropTypes.string.isRequired,
  memberPrice: PropTypes.string,
  productTitle: PropTypes.string,
  productText: PropTypes.string,
  productCode: PropTypes.string,
  showContractCareOf: PropTypes.bool,
  showContractIcon: PropTypes.bool,
  isFlyer: PropTypes.bool,
  isAssembly: PropTypes.bool,
  isSpecialDelivery: PropTypes.bool,
  isPromotional: PropTypes.bool,
  isShipSeparately: PropTypes.bool,
  isMarketPrice: PropTypes.bool,
  isCatalog: PropTypes.bool,
  isRestricted: PropTypes.bool,
  deliveryMessage: PropTypes.string,
  promoInfo: PropTypes.object,
  isSpecialOrder: PropTypes.bool,
  showProductStatus: PropTypes.bool,
  view: PropTypes.string,
  variantText: PropTypes.string,
  fontWeight: PropTypes.string,
  stockMessage: PropTypes.string,
  isNote: PropTypes.bool,
  lineNote: PropTypes.string,
  buyerId: PropTypes.string,
  productLinkUrl: PropTypes.string,
  leadTimeMessage: PropTypes.string,
  onSaveNote: PropTypes.func,
  orderProductId: PropTypes.string,
  isContractIconSetting: PropTypes.bool,
  customPromptElement: PropTypes.element,
  isDisableFooterHoverEffect: PropTypes.bool,
}

export const productContentPropTypes = {
  language: PropTypes.string.isRequired,
  productText: PropTypes.string,
  memberPrice: PropTypes.string,
  productCode: PropTypes.string,
  showContractCareOf: PropTypes.bool,
  showContractIcon: PropTypes.bool,
  isFlyer: PropTypes.bool,
  isAssembly: PropTypes.bool,
  isSpecialDelivery: PropTypes.bool,
  isPromotional: PropTypes.bool,
  isShipSeparately: PropTypes.bool,
  isMarketPrice: PropTypes.bool,
  isCatalog: PropTypes.bool,
  isRestricted: PropTypes.bool,
  showProductStatus: PropTypes.bool,
  view: PropTypes.string,
  deliveryMessage: PropTypes.string,
  promoInfo: PropTypes.object,
  stockMessage: PropTypes.string,
  lineNote: PropTypes.string,
  buyerId: PropTypes.string,
  leadTimeMessage: PropTypes.string,
  onSaveNote: PropTypes.func,
  orderProductId: PropTypes.string,
  isContractIconSetting: PropTypes.bool,
  isSpecialOrder: PropTypes.bool,
  isNote: PropTypes.bool,
  customPromptElement: PropTypes.element,
  isDisableBrandName: PropTypes.bool,
  isDisableFooterHoverEffect: PropTypes.bool,
}
