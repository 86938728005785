export default {
  en: {
    brandText: 'Brand:',
    itemText: 'Item#:',
    lineNotePlaceholder: 'Note',
  },
  fr: {
    brandText: 'Marque:',
    itemText: 'Article#:',
    lineNotePlaceholder: 'Remarque',
  },
}
