import {
  LIST_PRICE_ONLY,
  LIST_PRICE_OR_NONE,
  MARKET_OR_LIST_PRICE,
} from '../ProductCardConstants'
import messages from './messages'

export function getPriceForFooter(
  showSecondaryPrice,
  isMarketPrice,
  marketPrice,
  listPrice,
  language
) {
  let secondaryPrice = 0
  let secondaryPriceText = ''
  if (showSecondaryPrice === MARKET_OR_LIST_PRICE) {
    secondaryPrice = isMarketPrice ? marketPrice : listPrice
    secondaryPriceText = isMarketPrice
      ? messages[language].marketPrice
      : messages[language].listPrice
  } else if (
    (showSecondaryPrice === LIST_PRICE_OR_NONE && !isMarketPrice) ||
    showSecondaryPrice === LIST_PRICE_ONLY
  ) {
    secondaryPrice = listPrice
    secondaryPriceText = messages[language].listPrice
  }
  return { secondaryPrice, secondaryPriceText }
}
