import { generateProductUrlWithAdobeAnalytics } from '../ProductCardUtils'

export const formatProductProps = (p, inputProps) => {
  const {
    Brand,
    ProductCode,
    IsFlyer,
    TieredPricing: { Prices } = {},
    IsDropShip,
    ProductOnSale: { OnSaleEndDate } = {},
    ProductReview: { ReviewCount, ReviewRatings, ShowReview } = {},
    PromoInfo = {},
    IsSpecialOrder,
    QualifierType,
    OrderProductId,
    RichRelevanceAnalyticsUrl: richRelevanceUrl,
    deliveryMessage = '',
    stockMessage,
    price = 0,
    saving = 0,
    productPrice = 0,
    fees,
    isPromotional,
    ecoFeeIndex,
    tariffFeeIndex,
    handlingFees,
    savingsAsPercentage,
    calculatedSavings,
    calculatedTotalPrice,
    productTitle,
    imageUrl,
    isGiveAway,
    isFreeGiveAway,
    sellPrice,
    minimumQuantity,
    backOrderQuantity,
    quantity,
    showSubTotal,
    leadTimeMessage,
  } = p

  const { showContractIcon, pageName, callingLocation, enableInstrumentation } =
    inputProps

  const productLinkUrl = generateProductUrlWithAdobeAnalytics({
    pageName,
    callingLocation,
    productCode: ProductCode,
    richRelevanceUrl,
    enableInstrumentation,
  })

  const productHeader = {
    brand: Brand,
    shortDescription: p.ShortDescription,
    imageUrl,
  }

  const containsPromotion = PromoInfo.ContainPromo
  const productBody = {
    memberPrice: p.StandingOfferNumber,
    productTitle,
    productText: Brand,
    deliveryMessage,
    promoInfo: PromoInfo,
    isSpecialOrder: IsSpecialOrder,
    lineNote: p.LineNote,
    stockMessage,
    leadTimeMessage,
    productLinkUrl,
    reviewCount: ReviewCount,
    reviewRatings: ReviewRatings,
    showReview: ShowReview,
  }

  const productFooter = {
    price,
    productPrice,
    sellPrice,
    finalSellPrice: p.FinalSellPrice,
    customUnitOfMeasures: p.UOM, // UOM stands for Unit Of Mesure / customUnitOfMeasures
    listPrice: p.CombinedDetailPrice,
    quantity,
    marketPrice: p.CombinedMarketPrice,
    combinedLinePrice: p.CombinedLinePrice,
    qualifierType: QualifierType,
    showSubTotal,
    saving,
    unitSellPrice: p.CombinedFinalSellPrice,
    stockMessage,
    deliveryMessage,
    basePrice: p.BasePrice,
    backOrderQuantity,
    isDropShip: IsDropShip,
    productLinkUrl,
    salesMultiple: p.Multiple,
    containsPromotion,
    pageName,
    fees,
    ecoFeeIndex,
    tariffFeeIndex,
    handlingFees,
    savingsAsPercentage,
    calculatedSavings,
    calculatedTotalPrice,
  }

  const productCommon = {
    productCode: ProductCode,
    showContractIcon: showContractIcon && p.IsContract,
    isFlyer: IsFlyer,
    tieredPricing: Prices,
    isAssembly: !!p.IsAssembly,
    isSpecialDelivery: p.IsSpecialDelivery,
    isPromotional,
    isShipSeparately: IsDropShip,
    isMarketPrice: p.IsMarketPrice,
    isCatalog: p.IsCatalog,
    isRestricted: p.IsRestricted,
    orderProductId: OrderProductId,
    id: OrderProductId,
    onSaleEndDate: OnSaleEndDate,
    minimumQuantity,
    isGiveAway,
    isFreeGiveAway,
    salesMultiple: p.Multiple,
  }
  return {
    productHeader,
    productBody,
    productFooter,
    productCommon,
  }
}
