import PropTypes from 'prop-types'
/**
 * Takes an html value from eway, dans transforms it to HTML
 * Sometimes API routes do return to us HTML that needs to be
 * Displayed
 */
const FormatHtmlSpan = ({ text, children }) => {
  const value = text || children
  return value ? (
    <span
      dangerouslySetInnerHTML={{
        __html: `(${value})`,
      }}
    />
  ) : null
}

FormatHtmlSpan.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
}

export default FormatHtmlSpan
