export default {
  en: {
    quantity: 'Quantity',
    ship: 'Ship:',
    backOrder: 'Backorder:',
    minimumOrderMsg:
      'Quantity change was not accepted. This item has a minimum order quantity of {quantity}',
  },
  fr: {
    quantity: 'Quantité',
    ship: 'Expédié:',
    backOrder: 'À suivre:',
    minimumOrderMsg: `Le changement de quantité n'a pas été accepté. Cet article a une quantité minimum de commande de {quantity}`,
  },
}
